import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, first, map } from 'rxjs';
import { selectUserRoles } from 'src/app/auth/state/selectors/auth.selectors';
import { User } from '../models/users';

@Injectable({
  providedIn: 'root',
})
export class HasManagerRoleGuard {
  private store = inject(Store);
  private router = inject(Router);


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(selectUserRoles).pipe(
      first(Boolean),
      map((roles: User.UserRole[]) => {
        const hasManagerRole = roles.some(role => [User.UserRole.MANAGER].includes(role));
        if (hasManagerRole) {
          return true;
        } else {
          this.router.navigate(['']);
          return false;
        }
      })
    );
  }
}
